import * as moment from "moment";

function upperCaseFirstLetter(labelDate) {
  return labelDate.substring(0, 1).toUpperCase().concat(labelDate.substring(1));
}

export function formatPeriod(period) {
  let periods = [
    { name: "Este mês", id: 0 },
    { name: "Mês passado", id: 1 },
    { name: "Últimos três meses", id: 2 },
    { name: "Este ano", id: 3 },
    { name: "Ano anterior", id: 4 },
    { name: "Personalizado", id: 5 },
    // { name: 'Todo o período', id: 6 },
  ];
  let today = moment().locale("pt-br").format("MMMM [de] YYYY");
  let monthNumber = moment().format("MM");
  let showCalendar = false;
  let selectedDates = [];

  const lastThreeMonths = 3;
  const october2021 = "2021-10";
  let label = "";
  let requestDate = "";

  switch (period) {
    case periods[0].name: //'Este mês'
      requestDate = moment().format("YYYY-MM");
      selectedDates.push({
        id: 0,
        label: upperCaseFirstLetter(today),
        requestDate,
      });
      break;

    case periods[1].name: // 'Mês passado'
      label = moment()
        .locale("pt-br")
        .subtract(1, "months")
        .format("MMMM [de] YYYY");
      requestDate = moment().subtract(1, "months").format("YYYY-MM");

      selectedDates.push({
        id: 0,
        label: upperCaseFirstLetter(label),
        requestDate,
      });
      break;

    case periods[2].name: // 'Últimos três meses'
      for (let i = 0; i < lastThreeMonths; i++) {
        label = moment()
          .locale("pt-br")
          .subtract(i, "months")
          .format("MMMM [de] YYYY");
        requestDate = moment().subtract(i, "months").format("YYYY-MM");

        selectedDates.push({
          id: i,
          label: upperCaseFirstLetter(label), //"Novembro de 2021"
          requestDate, //"2021-11"
        });

        // Não mostrar meses antes de outubro de 2021
        if (requestDate == october2021) break;
      }
      break;

    case periods[3].name: // 'Este ano'
      for (let i = 0; i < monthNumber; i++) {
        label = moment()
          .locale("pt-br")
          .subtract(i, "months")
          .format("MMMM [de] YYYY");
        requestDate = moment().subtract(i, "month").format("YYYY-MM");
        selectedDates.push({
          id: i,
          label: upperCaseFirstLetter(label),
          requestDate,
        });

        if (requestDate == october2021) break;
      }
      break;

    case periods[4].name: // 'Ano anterior'
      for (let i = 1; i <= 12; i++) {
        label = moment(moment().startOf("year").format("ll"))
          .locale("pt-br")
          .add(-i, "month")
          .format("MMMM [de] YYYY");
        requestDate = moment(moment().startOf("year").format("ll"))
          .add(-i, "month")
          .format("YYYY-MM");
        selectedDates.push({
          id: i - 1,
          label: upperCaseFirstLetter(label),
          requestDate,
        });
      }
      break;

    case periods[5].name: // personalizado
      showCalendar = true;
      break;
  }
  return [selectedDates, showCalendar];
}
