<template>
  <v-container fluid class="pa-0">
    <v-menu
      ref="timeInterval"
      v-model="openCalendar"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="timeIntervalFormatted"
          label="Intervalo de tempo"
          prepend-icon
          readonly
          v-on="on"
          :color="primaryColor"
          single-line
          class="mx-4 pa-0"
          :outlined="reseller"
          :autofocus="autofocus"
        ></v-text-field>
      </template>

      <v-date-picker
        v-model="timeInterval"
        range
        :color="primaryColor"
        locale="pt-br"
        :type="type"
        :max="maximumDate || ''"
        :min="minimumDate || '2020-03-01'"
      >
        <v-spacer></v-spacer>
        <v-btn text :color="primaryColor" @click="openCalendar = false"
          >OK</v-btn
        >
      </v-date-picker>
    </v-menu>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Calendar",
  props: ["type", "maximumDate", "minimumDate", "reseller", "autofocus"],
  data() {
    return {
      timeInterval: [],
      openCalendar: false,
    };
  },

  computed: {
    ...mapGetters(["primaryColor"]),

    timeIntervalFormatted() {
      let date1 = this.timeInterval[0];
      let date2 = this.timeInterval[1];

      return this.organizeDate(date1, date2);
    },
  },

  methods: {
    organizeDate(date1, date2) {
      if (!date1) return null;

      if (date2 != null && date2 != "") {
        if (date1 <= date2) {
          this.$emit("selectedDate", [date1, date2]);
          date1 = this.formatDate(date1);
          date2 = this.formatDate(date2);
        } else {
          let interval = [date2, date1];
          this.$emit("selectedDate", interval);
          date1 = this.formatDate(interval[0]);
          date2 = this.formatDate(interval[1]);
          this.timeInterval = interval;
        }
        return `${date1} - ${date2}`;
      } else {
        this.$emit("selectedDate", [date1, date1]);
        date1 = this.formatDate(date1);
        return date1;
      }
    },

    formatDate(date) {
      const [year, month, day] = date.split("-");
      if (this.type == "month") {
        return `${month}/${year}`;
      }

      return `${day}/${month}/${year}`;
    },
  },
};
</script>
