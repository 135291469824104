<template>
  <div>
    <div class="mt-2 d-flex align-center" v-if="!editingTemplate">
      <h1 class="font-weight-bold" style="font-size: 22px">
        {{ $t("companyProfile.marketingInfo") }}
      </h1>
    </div>
    <div class="mt-3 pa-3">
      <v-row v-for="tag in editableCompanyTagsNames" :key="tag">
        <v-col
          v-if="tag != 'banner_url'"
          cols="12"
          sm="3"
          align-self="start"
          class="pa-0 pl-4"
          style="margin-top: 6px"
        >
          <v-card-text class="font-weight-bold body-2 pa-0"
            >{{ $t(`companyProfile.companyMarketingTags.${tag}`) }}
          </v-card-text>
        </v-col>

        <v-col
          v-if="tag != 'banner_url'"
          class="text-left pa-0"
          align-self="center"
          cols="12"
          sm="8"
        >
          <v-text-field
            v-if="companyMarketingTags[tag].hasPhoneMask"
            color="accentSuite"
            v-model="tagsEdit[tag]"
            class="pa-0 my-0 body-2"
            autocomplete="off"
            :rules="[
              (v) =>
                v
                  ? v.length <= 20 || $t('textFieldRules.max20characters')
                  : true,
            ]"
            :hint="$t('companyProfile.phoneHint')"
          />
          <v-text-field
            v-else
            color="accentSuite"
            v-model="tagsEdit[tag]"
            :disabled="companyMarketingTags[tag].disabled"
            :type="companyMarketingTags[tag].type"
            :placeholder="companyMarketingTags[tag].placeholder"
            class="pa-0 my-0 body-2"
            :hint="inputHint(tag)"
            :rules="tagRules"
          />
        </v-col>
        <div v-else class="mb-5 pa-3" style="width: 100%">
          <div style="max-width: 100%" class="mt-1 mb-2 d-flex align-center">
            <v-btn
              elevation="0"
              outlined
              text
              small
              class="text-none font-weight-bold mr-5"
              @click="$emit('updateBannerUrl')"
            >
              <v-icon left dark> mdi-cloud-upload-outline </v-icon>
              {{ $t("companyProfile.uploadBanner") }}</v-btn
            >

            <v-btn
              :disabled="!bannerUrl"
              @click="$emit('removeBannerUrl')"
              small
              icon
              class="pa-0 text-none font-weight-bold text-body-2 red--text"
              ><v-icon>mdi-trash-can-outline</v-icon></v-btn
            >
          </div>
          <v-img
            v-if="bannerUrl"
            :src="tagsEdit.banner_url"
            max-height="380"
          ></v-img>
          <v-card
            @click="$emit('updateBannerUrl')"
            v-else
            elevation="0"
            class="d-flex align-center justify-center default-image-preview"
            max-width="100%"
          >
            <v-icon color="grey" class="py-10" size="50">mdi-image-plus</v-icon>
          </v-card>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import { companyTags, companyMarketingTags } from "@//helpers/variables/tags";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { mask } from "vue-the-mask";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "MarketingInfo",
  directives: { mask },

  props: {
    bannerUrl: { type: String, default: "" },
  },

  data() {
    return {
      errorMessages,
      loading: false,
      tagsEdit: {},
      companyMarketingTags,
      companyTags,
      impactedTemplates: [],
    };
  },

  watch: {
    tagsEdit: {
      deep: true,
      handler(newVal) {
        this.$emit("updateTagsInfo", newVal);
      },
    },
    bannerUrl: {
      deep: true,
      handler(newVal) {
        this.tagsEdit.banner_url = newVal;
      },
    },
  },

  beforeMount() {
    this.getCompanyInfo();
  },

  computed: {
    ...mapGetters(["company"]),

    editableCompanyTagsNames() {
      return Object.keys(this.companyMarketingTags);
    },

    tagRules() {
      return [
        (v) =>
          String(v).length <= 1490 ||
          this.$t("textFieldRules.max1490characters"),
      ];
    },

    editingTemplate() {
      return this.$route.name === "TemplateEdit";
    },
  },

  methods: {
    ...mapActions(["getCompany"]),
    ...mapMutations(["setSnackBar", "setCompany"]),

    resetFields() {
      this.updateTags();
    },

    inputHint(tag) {
      return companyMarketingTags[tag].hint
        ? this.$t(`companyProfile.companyMarketingTagsHint.${tag}`)
        : "";
    },

    async getCompanyInfo() {
      this.loading = true;
      const snackbarConfig = { show: true };
      if (this.company) {
        this.updateTags();
      } else {
        snackbarConfig.message = errorMessages.unknown;
        snackbarConfig.color = "error";
        this.setSnackBar(snackbarConfig);
      }

      this.loading = false;
    },

    updateTags() {
      this.tagsEdit = companyTags.reduce((a, e) => {
        a[e] = this.company[e] !== undefined ? this.company[e] : "";
        return a;
      }, {});
    },
  },
};
</script>

<style scoped>
.default-image-preview {
  background-color: #dfdfdf;
  transition: 0.2s ease-in;
}

.default-image-preview:hover {
  background-color: #cfcfcf;
}
</style>
