<template>
  <div>
    <div v-if="!isSelfCheckout" class="mt-2 mb-10 d-flex align-center">
      <h1 class="font-weight-bold mr-4" style="font-size: 22px">
        {{ $t("companyProfile.billingInfo") }}
      </h1>
      <p class="ma-0 grey--text text-caption">
        <span class="red--text">*</span> {{ $t("common.requiredField") }}
      </p>
    </div>
    <v-form
      class="ma-0"
      ref="formBilling"
      v-model="validBillingFields"
      lazy-validation
      onsubmit="return false;"
    >
      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0"
            >CNPJ <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col
          class="text-left py-1"
          align-self="start"
          cols="12"
          sm="8"
          md="9"
        >
          <v-text-field
            color="primary"
            ref="cnpj"
            class="pa-0 my-0 mx-4 body-2"
            :value="editedData.cnpj"
            @input="fetchInfoByCNPJ"
            :rules="cnpjRule"
            v-mask="'##.###.###/####-##'"
            placeholder="CNPJ"
            required
            :disabled="hasCNPJ"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0">
            {{ $t("companyProfile.corporate") }}
            <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col
          class="text-left py-1"
          align-self="start"
          cols="12"
          sm="8"
          md="9"
        >
          <v-text-field
            color="primary"
            ref="rsocial"
            class="pa-0 my-0 mx-4 body-2"
            :value="editedData.corporate_name"
            :placeholder="$t('companyProfile.corporate')"
            required
            disabled
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0"
            >{{ $t("companyProfile.zipCode") }}
            <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col
          class="text-left py-1"
          align-self="start"
          cols="12"
          sm="8"
          md="9"
        >
          <v-text-field
            color="primary"
            ref="cep"
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.address_postal_code"
            v-mask="'##.###-###'"
            :placeholder="$t('companyProfile.zipCode')"
            disabled
            required
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0">
            {{ $t("companyProfile.neighborhood") }}
            <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col
          class="text-left py-1"
          align-self="start"
          cols="12"
          sm="8"
          md="9"
        >
          <v-text-field
            color="primary"
            ref="district"
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.district"
            :placeholder="$t('companyProfile.neighborhood')"
            disabled
            required
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0"
            >{{ $t("companyProfile.address") }}
            <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col class="text-left py-1" align-self="start" cols="8" sm="5" md="6">
          <v-text-field
            color="primary"
            ref="street"
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.address_info"
            :loading="loadingAddressFields"
            :placeholder="$t('companyProfile.address')"
            disabled
            required
          ></v-text-field>
        </v-col>
        <v-col class="text-left py-1" align-self="start" cols="4" sm="3" md="3">
          <v-text-field
            color="primary"
            ref="number"
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.address_number"
            :placeholder="$t('companyProfile.number')"
            disabled
            required
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="0" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0">{{
            $t("companyProfile.complement")
          }}</v-card-text>
        </v-col>
        <v-col class="text-left py-1" align-self="start" cols="6" sm="8" md="9">
          <v-text-field
            color="primary"
            ref="complement"
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.address_complement"
            :placeholder="$t('companyProfile.complement')"
            disabled
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0"
            >{{ $t("companyProfile.city") }}/UF
            <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col class="text-left py-1" align-self="start" cols="8" sm="5" md="6">
          <v-text-field
            color="primary"
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.address_city"
            :placeholder="$t('companyProfile.city')"
            disabled
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
        <v-col class="text-left py-1" align-self="start" cols="4" sm="3" md="3">
          <v-text-field
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.address_state"
            placeholder="UF"
            disabled
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0">
            {{ $t("companyProfile.financeEmail") }}
            <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col
          class="text-left py-1"
          align-self="start"
          cols="12"
          sm="8"
          md="9"
        >
          <v-text-field
            color="primary"
            class="pa-0 my-0 mx-4 body-2"
            type="email"
            v-model="editedData.financial_email"
            :rules="emailRule"
            placeholder="E-mail"
            required
            @input="emitUnmaskedBillingData"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import {
  maskCNPJ,
  maskCEP,
  preValidateCNPJ,
  removeMask,
} from "@/helpers/services/utils";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { errorMessages } from "@/helpers/variables/snackbarMessages";

export default {
  directives: { mask },

  props: {
    companyProfile: {
      type: Object,
      default: () => ({}),
    },
    isSelfCheckout: { type: Boolean, default: false },
  },

  data: () => ({
    loadingAddressFields: false,
    snackbar: {
      isSuccess: true,
      show: false,
      text: "",
    },
    showLoading: false,
    validBillingFields: true,
    editedData: {
      financial_email: "",
      cnpj: "",
      corporate_name: "",
      address_postal_code: "",
      address_info: "",
      address_number: "",
      address_complement: "",
      address_city: "",
      address_state: "",
      district: "",
    },

    errorMessages,
  }),

  computed: {
    ...mapGetters(["token", "snackbarMessageKey", "company"]),

    isCompanyProfile() {
      return this.$route.name === "Company";
    },

    cnpjRule() {
      return [
        (v) => !!v || this.$t("textFieldRules.requiredCNPJ"),

        (v) =>
          (v && v.length === 18) || this.$t("textFieldRules.insertingCNPJ"),
      ];
    },

    emailRule() {
      return [
        (v) => !!v || this.$t("textFieldRules.requiredEmail"),
        // Os finais de e-mail aceitos estão baseados nos clientes ativos do sistema conforme painel do Reseller
        (v) =>
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) ||
          this.$t("textFieldRules.invalidEmail"),
      ];
    },

    hasCNPJ() {
      return !!this.companyProfile.cnpj;
    },
  },

  methods: {
    ...mapActions(["getCompanyAddressByCnpj"]),

    ...mapMutations(["setCompany", "setSelectedClient", "setSnackBar"]),

    maskCNPJ,
    maskCEP,
    preValidateCNPJ,
    removeMask,

    resetFields() {
      this.setEditableBillingData();
    },

    emitUnmaskedBillingData() {
      const { cnpj, address_postal_code } = this.editedData;

      const unmaskedPayload = {
        ...this.editedData,
        cnpj: removeMask("cnpj", cnpj),
        address_postal_code: removeMask(
          "address_postal_code",
          address_postal_code
        ),
      };

      this.$emit("updateCompanyInfo", unmaskedPayload);
    },

    async updateCorporateNameByCNPJ() {
      const cnpj = removeMask("cnpj", this.editedData.cnpj);
      this.showLoading = true;

      await this.getCompanyAddressByCnpj(cnpj)
        .then((response) => {
          this.assignCompanyAddressByCnpjApi(response.data);
          this.updateCompany();
        })
        .catch((error) => {
          const { data } = error.response;
          console.error("updateError()", data);
          this.setSnackBar({
            message: data.message,
            show: true,
            color: "error",
          });
        })
        .finally(() => {
          this.showLoading = false;
        });
    },

    async fetchInfoByCNPJ(cnpj) {
      if (this.hasCNPJ) {
        return;
      }

      if (!preValidateCNPJ(cnpj)) {
        return;
      } else if (preValidateCNPJ(cnpj) && cnpj !== this.editedData.cnpj) {
        this.editedData.cnpj = cnpj;

        this.loadingAddressFields = true;
        this.getCompanyAddressByCnpj(removeMask("cnpj", cnpj))
          .then(({ data }) => {
            this.assignCompanyAddressByCnpjApi(data);
            this.emitUnmaskedBillingData();
          })
          .catch((error) => {
            this.setSnackBar({
              is_key: true,
              message_key: error.response.data.code,
              show: true,
              color: "error",
            });
            this.clearCnpjAddressFields();
            console.error(error);
          })
          .finally(() => {
            this.loadingAddressFields = false;
            this.$emit("updateBillingInfo", this.editedData);
          });
      }
    },

    setEditableBillingData() {
      this.editedData = {
        cnpj: this.maskCNPJ(this.companyProfile.cnpj),
        address_postal_code: this.maskCEP(
          this.companyProfile.address_postal_code
        ),
      };

      [
        "address_info",
        "address_number",
        "address_complement",
        "address_city",
        "address_state",
        "financial_email",
        "district",
        "corporate_name",
      ].forEach(
        (value) => (this.editedData[value] = this.companyProfile[value])
      );
    },

    async updateCompany() {
      this.showLoading = true;
      if (!this.$refs.formBilling.validate()) {
        this.showLoading = false;
        this.setSnackBar({
          message: this.$t("errorMessages.fillingRequiredFields"),
          show: true,
          color: "error",
        });
      }

      let url =
        this.companyProfile.key == this.company.key
          ? `${process.env.VUE_APP_API_BASE_URL}/company`
          : `${process.env.VUE_APP_API_BASE_URL}/company/${this.companyProfile.key}`;

      const payload = {
        ...this.editedData,
        cnpj: removeMask("cnpj", this.editedData.cnpj),
        address_postal_code: removeMask(
          "address_postal_code",
          this.editedData.address_postal_code
        ),
      };
      let auth = {
        headers: {
          Authorization: this.token,
        },
      };
      this.$axios
        .put(url, payload, auth)
        .then(({ data }) => {
          if (this.isSelfCheckout || this.isCompanyProfile) {
            this.setCompany(data);
          } else {
            this.setSelectedClient(data);
          }
          this.setSnackBar({
            message: this.$t("successMessages.dataUpdated"),
            show: true,
            color: "success",
          });
        })
        .catch((error) => {
          this.setSnackBar({
            message: this.$t("errorMessages.updateCompanyData"),
            show: true,
            color: "error",
          });
          console.error("updateCompany()", error);
        })
        .finally(() => {
          this.showLoading = false;
          this.$emit("close");
        });
    },

    setFocus(input) {
      this.$refs[input].focus();
    },

    assignCompanyAddressByCnpjApi(data) {
      this.editedData["address_postal_code"] = data.cep;
      this.editedData["address_info"] = data.logradouro;
      this.editedData["address_number"] = data.numero;
      this.editedData["address_complement"] = data.complemento;
      this.editedData["address_city"] = data.municipio;
      this.editedData["address_state"] = data.uf;
      this.editedData["corporate_name"] = data.nome;
      this.editedData["district"] = data.bairro;
      this.editedData["cnpj"] = data.cnpj;
    },

    clearCnpjAddressFields() {
      this.editedData = {
        address_postal_code: "",
        address_info: "",
        address_number: "",
        address_complement: "",
        address_city: "",
        address_state: "",
        corporate_name: "",
        district: "",
        cnpj: "",
        financial_email: this.companyProfile["financial_email"],
      };
    },
  },

  async mounted() {
    this.setEditableBillingData();

    if (this.editedData.cnpj && !this.companyProfile.corporate_name) {
      await this.updateCorporateNameByCNPJ();
    }
  },
};
</script>
