import axios from "axios";

export async function updateControlDomainStatus(companyDomain) {
  const url = process.env.VUE_APP_CLOUD_FUNCTION_DOMAIN_STATUS;

  let result = false;

  await axios
    .put(url, {
      company_domain: companyDomain,
    })
    .then((response) => {
      result = response;
    })
    .catch((err) => {
      console.warn("Vuex: updateControlDomainStatus()", err);
    });

  return result;
}

export async function getAdvancedLogoutStatus(companyDomain) {
  const url = process.env.VUE_APP_CLOUD_FUNCTION_ADVANCED_LOGOUT;

  let result = false;

  await axios
    .get(url, {
      params: {
        company_domain: companyDomain,
      },
    })
    .then(function (response) {
      result = response;
    })
    .catch((err) => {
      console.warn("Vuex: getAdvancedLogoutStatus()", err);
    });

  return result;
}
