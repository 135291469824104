<template>
  <v-card
    outlined
    hover
    :class="`${styles} mb-4 ${!isNew ? 'px-2' : 'pl-2'} d-flex flex-column ${
      isNew ? 'align-center justify-center' : 'justify-space-between'
    } cursor-pointer`"
    height="250"
    width="100%"
    :disabled="disabled"
  >
    <!-- IF IT'S TO PURCHASE A NEW PLAN -->
    <div v-if="isNew" class="justify-center align-center mb-4">
      <v-icon
        size="110"
        class="new-service-icon my-0 pa-0"
        v-text="'mdi-plus'"
      />
      <div class="font-weight-medium primary--text new-service-title pt-3">
        {{ title }}
      </div>
    </div>

    <!-- PLAN GENERAL INFO (NAME, SUBSCRIPTION AND END TRIAL) -->
    <div v-else class="justify-start my-0">
      <v-card-text
        class="text-center my-0 pb-0 d-flex flex-column align-center"
      >
        <div style="width: 40px; height: 40px" class="mb-4">
          <Logo
            height="40"
            class="mx-auto"
            :product="logo"
            no-margins
            products
            hide-labels
          />
        </div>

        <h4
          class="text-center text-capitalize caption font-weight-bold mt-0 pt-0`"
        >
          {{ formatModuleName(title) }}
        </h4>
      </v-card-text>

      <v-card-subtitle
        v-if="!isNew"
        :class="`trialend grey--text text--darken-4 mt-2 ${
          isTrialAndIsExpired ? 'my-auto' : 'mt-0'
        } caption pa-0`"
      >
        <div>{{ getPlanType }}</div>
        <div
          v-if="isTrialAndIsExpired"
          class="red--text text--darken-4 font-weight-bold mb-2 mx-4 trialend"
        >
          {{ $t("companyPlansInfo.endTrial") }}<br />
          {{ formatDateFullMonth(endTrial, "DD/MM/YYYY") }}
        </div>
        <div
          v-else-if="isTrialAndExpiresToday"
          class="orange--text text--darken-4 font-weight-bold mb-2 mx-4 trialend"
        >
          {{ $t("companyPlansInfo.endTrial") }}<br />
          {{ formatDateFullMonth(endTrial, "DD/MM/YYYY") }}
        </div>
        <div
          v-else-if="isTrialAndExpiresSoon"
          class="orange--text text--darken-4 font-weight-bold mb-2 mx-4 trialend"
        >
          {{ $t("companyPlansInfo.endTrial") }}<br />
          {{ formatDateFullMonth(endTrial, "DD/MM/YYYY") }}
        </div>
        <span v-if="!isService">
          {{
            `${licensesNumber}/${
              planSubscriptionType === SUBSCRIPTIONS.ANNUAL
                ? planMaxLicensesNumber
                : minLicenses
            }`
          }}
          {{ $t("common.licenses") }}
        </span>
      </v-card-subtitle>
    </div>

    <!-- STATUS -->
    <div v-if="!isNew">
      <div v-if="isService" class="text-center justify-end">
        <v-card-text class="caption d-flex align-center justify-center">
          <v-icon
            size="20"
            color="success"
            class="mx-1"
            v-text="'mdi-store-cog'"
          />
          {{ $t("common.service") }}
        </v-card-text>
      </div>
      <div v-else class="text-center justify-end">
        <v-card-text class="caption d-flex align-center justify-center">
          <v-icon
            size="20"
            :color="colorIcon[planStatus.toLowerCase()]"
            class="mx-1"
          >
            {{ statusIcon[planStatus.toLowerCase()] }}
          </v-icon>
          {{ $t(`plansStatus.${planStatus}`) }}
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>
<script>
import {
  formatModuleName,
  formatDateFullMonth,
  formatDate,
  checkExpiresToday,
  checkIfIsGoingToExpire,
} from "@/helpers/services/utils";
import { status, planPeriodic } from "@/helpers/variables/translateString";
import {
  STATUS,
  SUBSCRIPTIONS,
  MIN_LICENSES,
} from "@/helpers/variables/backendConstants.js";
import Logo from "@/components/base/Logo";
export default {
  components: { Logo },
  name: "PurchasedPlan",
  props: {
    isNew: { type: Boolean, default: false },
    plan: { type: Object, default: () => {} },
    styles: { type: String, default: "" },
    licensesNumber: { type: [String, Number], default: 0 },
    planKey: { type: String, default: "" },
    clientHasThisPlan: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      statusIcon: {
        active: "mdi-check-circle-outline",
        trial: "mdi-alert-circle-outline",
        disabled: "mdi-close-circle-outline",
        suspended: "mdi-alert-circle-outline",
      },
      colorIcon: {
        active: "success",
        trial: "warning",
        disabled: "disabled",
        suspended: "warning",
      },
      status,
      planPeriodic,
      STATUS,
      SUBSCRIPTIONS,
    };
  },

  computed: {
    isService() {
      return (
        this.planSubscriptionType == SUBSCRIPTIONS.ONCE &&
        this.planStatus == STATUS.DISABLED
      );
    },

    logo() {
      return this.isNew ? "" : this.plan.plan.short_code;
    },

    title() {
      return this.isNew ? this.$t("common.newPlan") : this.plan.plan.name;
    },

    planStatus() {
      return this.isNew ? "" : this.plan.status;
    },

    planSubscriptionType() {
      return this.isNew ? "" : this.plan.subscription_type;
    },

    planMaxLicensesNumber() {
      return this.isNew ? 0 : this.plan.max_licenses;
    },

    minLicenses() {
      if (!this.plan.min_licenses) return this.licensesNumber;

      return this.licensesNumber < MIN_LICENSES
        ? MIN_LICENSES
        : this.licensesNumber;
    },

    endTrial() {
      return this.isNew ? "" : this.plan.end_trial;
    },

    planProducts() {
      return this.isNew ? [] : this.plan.plan.products;
    },

    getPlanType() {
      if (this.planStatus === "TRIAL") {
        return this.$t("common.trialPlan");
      } else {
        return `${this.isService ? "" : this.$t("common.plan")} ${this.$t(
          `planPeriodDescription.${this.planSubscriptionType}`
        )}`;
      }
    },

    isTrialAndIsExpired() {
      return this.plan.valid_status === STATUS.TRIAL_EXPIRED;
    },

    isTrialAndExpiresToday() {
      return this.planStatus === "TRIAL" && checkExpiresToday(this.endTrial);
    },
    isTrialAndExpiresSoon() {
      return (
        this.planStatus === "TRIAL" && checkIfIsGoingToExpire(this.endTrial)
      );
    },

    isCellPhone() {
      return this.$vuetify.breakpoint.xs;
    },

    getWidth() {
      return this.isCellPhone ? "89vw" : "200";
    },

    // Irá retornar o nome dos produtos deixando sempre na frente o Conecta Suite
    productsNames() {
      return this.planProducts
        ? this.planProducts
            .map((product) => product.name)
            .sort(function (a, b) {
              if (a == "conecta_suite") {
                return -1;
              }
              if (b == "conecta_suite") {
                return 1;
              }
              if (a < b) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            })
        : [];
    },
  },

  methods: {
    formatModuleName,

    formatDateFullMonth,

    formatDate,

    checkExpiresToday,

    goToDetails() {
      let links = {
        conecta_sign: "https://conectasuite.com/app/conecta-sign/",
        conecta_suite: "https://conectasuite.com/",
        conecta_track: "https://conectasuite.com/app/conecta-track/",
        conecta_control: "https://conectasuite.com/app/access-control/",
      };
      return links[this.title];
    },
  },
};
</script>
<style scoped>
.black-and-white {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray; /* IE */
}
.module-card {
  cursor: pointer;
}
.new-service-icon {
  background: -moz-linear-gradient(top, #311f50 0%, #957ebd 100%) !important;
  background: -webkit-linear-gradient(top, #311f50 0%, #957ebd 100%) !important;
  background: linear-gradient(to bottom, #311f50 0%, #957ebd 100%) !important;
  -webkit-background-clip: text !important;
  -moz-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.new-service-title {
  font-size: 14px !important;
  margin: -10px 0 0 0 !important;
}
.select-product {
  text-transform: capitalize !important;
  font-weight: normal !important;
}
.trialend {
  font-size: 11.8px !important;
  line-height: 15px !important;
}
</style>
