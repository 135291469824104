<template>
  <CompanyProfileForm :companyProfile="company" :startingMenu="startingMenu" />
</template>

<script>
import CompanyProfileForm from "@/components/billing/CompanyProfileForm";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";

export default {
  name: "Profile",
  props: {
    startingMenu: { type: String, default: "" },
  },
  components: {
    CompanyProfileForm,
  },
  computed: {
    ...mapGetters(["company", "hasAcceptedDriveScopes"]),
  },
  methods: {
    ...mapActions(["checkScopes"]),
    ...mapMutations(["setAcceptedDriveScopes"]),
  },
  beforeMount() {
    if (!this.hasAcceptedDriveScopes) {
      const payload = {
        scopes: [INCREMENTAL_SCOPES.DRIVE],
      };

      this.checkScopes(payload)
        .then(() => {
          this.setAcceptedDriveScopes(true);
        })
        .catch(() => {
          this.setAcceptedDriveScopes(false);
        });
    }
  },
};
</script>

<style></style>
