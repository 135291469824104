<template>
  <editor
    v-if="active"
    :api-key="tinymceApiKey"
    v-model="templateHtml"
    :disabled="disabled"
    :init="editorConfig"
    model-events="change input keydown blur focus paste"
  />
</template>
<script>
import {
  applyImageStyles,
  convertDomToHtmlString,
  fluxTemplateToPreview,
  fluxTemplateToEditor,
  insertLinkInTagImage,
} from "@/helpers/services/tagPreview";
import { changeDriveURL } from "@/helpers/services/images";
import { getWhatsAppLink } from "@/helpers/services/utils";
import { social } from "@/helpers/variables/defaultImages";
import {
  allTags,
  orgTagsList,
  socialTypes,
  userTagsList,
  vacationTags,
} from "@/helpers/variables/tags";
import Editor from "@tinymce/tinymce-vue";
import { mapGetters } from "vuex";

export default {
  name: "Editor",
  components: {
    editor: Editor,
  },
  props: {
    html: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    height: { type: [Number, String], default: 400 },
    isVacation: { type: Boolean, default: false },
    simpleEditor: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
  },
  data() {
    return {
      templateHtml: "",
      active: false,
      bookmark: null,
      tinymceApiKey: process.env.VUE_APP_TINY_MCE_API_KEY,
    };
  },
  computed: {
    ...mapGetters([
      "companyLogo",
      "companyBanner",
      "companyBannerLink",
      "companyPreviewImage",
      "companyBannerPreviewImage",
      "userPreviewImage",
      "company",
      "currentUser",
      "users",
    ]),

    editorConfig() {
      const _this = this;
      return {
        setup: (editor) => {
          editor.on("init", async () => {
            editor.insertContent(_this.html);
            if (!_this.simpleEditor) {
              await _this.addLinkTipTinyMCE(editor);
            }
            _this.$emit("editor-ref", editor);
          });

          editor.on("blur", (editor) => {
            _this.temporaryHtml = applyImageStyles(_this.temporaryHtml);
            _this.bookmark = editor.target.selection.getBookmark();
          });

          editor.on("focus", (editor) => {
            if (_this.bookmark) {
              editor.target.selection.moveToBookmark(_this.bookmark);
              _this.bookmark = null;
            }
          });

          editor.on("BeforeSetContent", (e) => {
            // Substitui tag de link_social_media pela url
            if (
              [
                "[*COMPANY_FACEBOOK*]",
                "[*COMPANY_BANNER_LINK*]",
                "[*COMPANY_LINKEDIN*]",
                "[*COMPANY_TWITTER*]",
                "[*COMPANY_YOUTUBE*]",
                "[*COMPANY_INSTAGRAM*]",
                "[*COMPANY_WHATSAPP*]",
                "[*USER_WHATSAPP*]",
                "[*CALENDAR_SCHEDULES_LINK*]",
              ].includes(e.content)
            ) {
              if (e.content === "[*COMPANY_FACEBOOK*]") {
                e.content = `<a href="${this.company.social_media_facebook}">${e.content}</a>`;
              } else if (e.content === "[*COMPANY_LINKEDIN*]") {
                e.content = `<a href="${this.company.social_media_linkedin}">${e.content}</a>`;
              } else if (e.content === "[*COMPANY_TWITTER*]") {
                e.content = `<a href="${this.company.social_media_twitter}">${e.content}</a>`;
              } else if (e.content === "[*COMPANY_YOUTUBE*]") {
                e.content = `<a href="${this.company.social_media_youTube}">${e.content}</a>`;
              } else if (e.content === "[*COMPANY_WHATSAPP*]") {
                e.content = `<a href="${getWhatsAppLink(
                  this.company.social_media_whatsapp
                )}" target="_blank">${e.content}</a>`;
              } else if (e.content === "[*USER_WHATSAPP*]") {
                e.content = `<a href="${getWhatsAppLink(
                  this.currentUser.whatsapp_number
                )}" target="_blank">${e.content}</a>`;
              } else if (e.content === "[*COMPANY_BANNER_LINK*]") {
                e.content = `<a href="${this.company.banner_link}">${e.content}</a>`;
              } else if (e.content === "[*CALENDAR_SCHEDULES_LINK*]") {
                e.content = `<a href="${this.currentUser.calendar_schedules_link}">${e.content}</a>`;
              } else {
                e.content = `<a href="${this.company.social_media_instagram}">${e.content}</a>`;
              }
            }

            var element = new DOMParser().parseFromString(
              e.content,
              "text/html"
            );

            // Convert Google Drive image before insert into html
            if (
              e.content.startsWith('<img src="https://drive.google.com/file/d/')
            ) {
              element.getElementsByTagName("img").forEach((image) => {
                image.src = changeDriveURL(image.src);
                e.content = element.innerHTML;
              });
              e.content = convertDomToHtmlString(element);
            } else if (e.content.startsWith("<img src=")) {
              element.getElementsByTagName("img").forEach((image) => {
                image.src = changeDriveURL(image.src);
                if (!image.width) {
                  image.width = 400;
                }
                e.content = element.innerHTML;
              });
              e.content = convertDomToHtmlString(element);
            }

            if (e.content && e.content.includes("blob:")) {
              const s = e.content
                .substr(e.content.indexOf("blob"), e.content.length)
                .replace("/>", "")
                .replace(">", "")
                .replace('"', "")
                .trim();
              if (e.target.editorUpload.blobCache.getByUri(s)) {
                let size = e.target.editorUpload.blobCache
                  .getByUri(s)
                  .blob().size;
                const allowedSize = 10; // KB
                size = size / 1024; // KB
                if (size > allowedSize) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }
            }
          });

          editor.on("blur", function () {
            _this.temporaryHtml = applyImageStyles(_this.temporaryHtml);
          });

          editor.ui.registry.addIcon(
            "umbrella-beach",
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.13 14.56L14.56 13.13L21 19.57L19.57 21L13.13 14.56M17.42 8.83L20.28 5.97C16.33 2 9.93 2 6 5.95C9.91 4.65 14.29 5.7 17.42 8.83M5.95 6C2 9.93 2 16.33 5.97 20.28L8.83 17.42C5.7 14.29 4.65 9.91 5.95 6M5.97 5.96L5.96 5.97C5.58 9 7.13 12.85 10.26 16L16 10.26C12.86 7.13 9 5.58 5.97 5.96Z" /></svg>'
          );

          editor.ui.registry.addNestedMenuItem("user", {
            text: "Usuário ",
            icon: "user",
            getSubmenuItems: function () {
              const firstUser = _this.users[0];
              const userCustomTags = Object.values(firstUser.custom_tags);
              return [...userTagsList, ...userCustomTags].map((item) => {
                return {
                  type: "menuitem",
                  text: item.name,
                  onAction: () => {
                    editor.insertContent(_this.insertImages(item.tag));
                  },
                };
              });
            },
          });

          editor.ui.registry.addNestedMenuItem("company", {
            text: "Empresa ",
            icon: "bookmark",
            getSubmenuItems: function () {
              const customTags = Object.values(_this.company.custom_tags);
              return [...orgTagsList, ...customTags].map((item) => {
                return {
                  type: "menuitem",
                  text: item.name,
                  onAction: () =>
                    editor.insertContent(_this.insertImages(item.tag)),
                };
              });
            },
          });

          editor.ui.registry.addNestedMenuItem("vacation", {
            text: "Férias ",
            icon: "umbrella-beach",
            getSubmenuItems: function () {
              return vacationTags.map((item) => {
                return {
                  type: "menuitem",
                  text: item.name,
                  onAction: () =>
                    editor.insertContent(_this.insertImages(item.tag)),
                };
              });
            },
          });

          editor.ui.registry.addMenuItem("previewModel", {
            text: "Visualizar",
            icon: "preview",
            onAction: () => {
              this.$emit("preview");
            },
          });

          var onAction = function (autocompleteApi, rng, value) {
            editor.selection.setRng(rng);
            editor.insertContent(_this.insertImages(value));
            autocompleteApi.hide();
          };

          var getMatchedChars = function (pattern) {
            return allTags.filter(function (char) {
              return char.text.indexOf(pattern) !== -1;
            });
          };

          /* An autocompleter that allows you to insert special characters */
          editor.ui.registry.addAutocompleter("specialchars", {
            ch: ":",
            minChars: 1,
            columns: 1,
            highlightOn: ["text"],
            onAction: onAction,
            fetch: function (pattern) {
              return new Promise((resolve) => {
                function capitalizeFirstLetter(string) {
                  return string.charAt(0).toUpperCase() + string.slice(1);
                }

                var results = getMatchedChars(pattern).map(function (char) {
                  return {
                    type: "cardmenuitem",
                    value: char.value,
                    label: char.text,
                    items: [
                      {
                        type: "cardcontainer",
                        direction: "vertical",
                        items: [
                          {
                            type: "cardtext",
                            text: capitalizeFirstLetter(char.text),
                            name: "char_name",
                          },
                          {
                            type: "cardtext",
                            text: char.value,
                          },
                        ],
                      },
                    ],
                  };
                });
                resolve(results);
              });
            },
          });
        },
        height: _this.height,
        content_style: `
          body { font-family:Roboto,sans-serif; font-size:14px }

          .img_rounded_border {
            border-radius: 15% !important;
          }

          .img_circle_border {
            border-radius: 50% !important;
          }

          .img_square_border {
            border-radius: 0% !important;
          }
        `,
        menubar: _this.simpleEditor
          ? ""
          : "edit view insert format tools table template tags",
        image_advtab: true,
        image_class_list: [
          { title: "Nenhuma", value: "" },
          { title: "Borda arredondada", value: "img_rounded_border" },
          { title: "Borda redonda", value: "img_circle_border" },
          { title: "Borda quadrada", value: "img_square_border" },
        ],
        menu: {
          view: {
            title: "Visualizar",
            items: "code | visualblocks visualchars previewModel",
          },
          insert: {
            title: "Inserir",
            items: "image link inserttable | charmap emoticons",
          },
          tags: {
            title: "Adicionar Informações",
            items: _this.isVacation ? "user company vacation" : "user company",
          },
        },
        placeholder: _this.placeholder,
        urlconverter_callback: (url) => changeDriveURL(url, this.company),
        paste_as_text: true,
        resize_img_proportional: true,
        default_link_target: "_blank",
        link_title: false,
        table_default_attributes: {
          border: "0",
        },
        language_url:
          "https://firebasestorage.googleapis.com/v0/b/access-control-storage.appspot.com/o/translate%2Fpt_BR_01.js?alt=media&token=430a2f25-59f6-48e2-bcac-822dc9550711",
        language: "pt_BR",
        plugins:
          "save insertdatetime emoticons nonbreaking visualchars advlist anchor autolink charmap code codesample directionality help hr image link lists media preview print table template textpattern visualblocks visualchars",
        toolbar: this.editorToolbar,
        fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
        font_size_input_default_unit: "px",
        table_default_styles: {
          width: "800px",
        },
      };
    },

    simpleEditorConfig() {
      return "undo | bold italic strikethrough lineheight | link | alignleft aligncenter alignright | numlist bullist hr | removeformat";
    },

    defaultEditorConfig() {
      return "undo redo | formatselect fontselect fontsizeselect | bold italic strikethrough forecolor backcolor lineheight | link image | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent hr | removeformat | code";
    },

    editorToolbar() {
      return this.simpleEditor
        ? this.simpleEditorConfig
        : this.defaultEditorConfig;
    },
  },
  watch: {
    html(new_val, old_val) {
      if (new_val !== old_val) {
        this.$forceUpdate();
        this.templateHtml = fluxTemplateToPreview(this.html);
      }
    },
    templateHtml(new_val, old_val) {
      if (new_val !== old_val) {
        let newHtml = fluxTemplateToEditor(this.templateHtml);
        this.$emit("update", newHtml);
      }
    },
  },
  methods: {
    addLinkTipTinyMCE(editor) {
      this.showTagsLine = true;
      editor.windowManager.oldOpen = editor.windowManager.open;
      editor.windowManager.open = async function (t, r) {
        var modal = editor.windowManager.oldOpen.apply(this, [t, r]);
        if (t.title === "Insert/Edit Link") {
          let url = document.getElementsByClassName("tox-form__group")[0];
          let urlTipArea = document.createElement("small");
          let urlTip = document.createTextNode("https://dominio.com.br");
          urlTipArea.style.color = "gray";
          urlTipArea.style.fontStyle = "italic";
          urlTipArea.style.fontSize = "14px";
          urlTipArea.style.margin = "0 0 20px 0";
          urlTipArea.appendChild(urlTip);
          url.appendChild(urlTipArea);
        }
        if (t.title === "Source Code") {
          let dialog = document.getElementsByClassName("tox-dialog__title")[0];
          let headerDialog =
            document.getElementsByClassName("tox-dialog__header")[0];
          let elementDiv = document.createElement("div");
          let elementText = document.createElement("p");
          dialog.appendChild(elementDiv);
          elementDiv.appendChild(elementText);
          elementText.innerHTML =
            "<span style='color:#FF884D;font-size:24px; margin: 0px 6px;' class='mdi mdi-alert'></span> <span style='color:#802700'>Algumas personalizações CSS podem não funcionar na sua assinatura de e-mail. Confira a lista de atributos CSS permitidos pelo Google <a style='text-decoration:underline;cursor:pointer;color:blue' href='https://developers.google.com/gmail/design/css' target='_blank' rel='noopener noreferrer'>clicando aqui.</a> </span>";
          dialog.setAttribute("style", "align-items:start");
          elementText.style.width = "100%";
          elementText.style.padding = "2px 5px";
          elementDiv.style.marginTop = "7px";
          elementText.style.marginBottom = "2px";
          elementText.style.fontSize = "14px";
          elementDiv.style.backgroundColor = "#f5e3c4";
          elementDiv.style.borderRadius = "3px";
          dialog.style.width = "100%";
          elementDiv.style.fontStyle = "Roboto,sans-serif";
          elementText.style.display = "flex";
          elementText.style.alignItems = "center";
          headerDialog.style.alignItems = "start";
        }
        return modal;
      };
    },

    insertImages(tag) {
      if (tag === "[*COMPANY_LOGO_URL*]") {
        return `<div style="display: unset"><img src="${changeDriveURL(
          this.companyLogo || this.companyPreviewImage
        )}" alt="${tag}" width="300" /></div>&nbsp;`;
      } else if (tag === "[*COMPANY_BANNER_URL*]") {
        if (this.companyBannerLink) {
          return `<div style="display: unset"><a href="${
            this.companyBannerLink
          }"target="_blank"><img src="${changeDriveURL(
            this.companyBanner || this.companyBannerPreviewImage
          )}" alt="${tag}" width="600px" /></a></div>&nbsp;`;
        } else if (this.companyBannerLink === "" || !this.companyBannerLink) {
          return `<div style="display: unset"><img src="${changeDriveURL(
            this.companyBanner || this.companyBannerPreviewImage
          )}" alt="${tag}" width="600px" /></div>&nbsp;`;
        }
      } else if (["[*USER_SIGN_PHOTO*]", "[*USER_PHOTO*]"].includes(tag)) {
        return `<div style="display: unset"><img src="${this.userPreviewImage}" alt="${tag}" width="130" /></div>`;
      } else if (Object.keys(socialTypes).includes(tag)) {
        const iconImg = `<img src="${social[tag]}" alt="${tag}" width="25" />`;
        return `<div style="display: unset">${insertLinkInTagImage(
          iconImg,
          tag
        )}</div>`;
      } else {
        return tag;
      }
    },
  },
  mounted() {
    this.active = true;
  },

  destroyed() {
    this.active = false;
  },
};
</script>
