<template>
  <v-chip small :color="color" :class="classStyle">
    {{ planStatusLabel }}
  </v-chip>
</template>
<script>
import { STATUS } from "@/helpers/variables/backendConstants.js";
import { status as statusLabel } from "@/helpers/variables/translateString";
export default {
  name: "StatusBadge",

  props: {
    status: { type: String, required: true },
    validStatus: { type: String, required: true },
    endTrial: { type: String, required: true },
    classStyle: { type: String, default: "" },
  },

  data() {
    return {
      colors: {
        ACTIVE: "success",
        DISABLED: "secondary lighten-2",
        TRIAL: "warning",
        TRIAL_EXPIRED: "error",
        SUSPENDED: "warning",
      },
      STATUS,
      statusLabel,
    };
  },

  computed: {
    color() {
      return this.colors[this.validStatus];
    },
    planStatusLabel() {
      return this.$t(`plansStatus.${this.validStatus}`);
    },
    isTrial() {
      return this.status === STATUS.TRIAL;
    },
    isExpiredTrial() {
      return this.validStatus === STATUS.TRIAL_EXPIRED;
    },
  },
};
</script>
