<template>
  <div>
    <div class="mt-2 d-flex align-center" v-if="!editingTemplate">
      <h1 class="font-weight-bold" style="font-size: 22px">
        {{ $t("companyProfile.contactInfo") }}
      </h1>
    </div>
    <div class="mt-8 pa-3">
      <v-row v-for="tag in editableCompanyTagsNames" :key="tag">
        <v-col
          cols="12"
          sm="3"
          align-self="start"
          class="pa-0 pl-4"
          style="margin-top: 6px"
        >
          <v-card-text class="font-weight-bold body-2 pa-0"
            >{{ $t(`companyProfile.companyContactTags.${tag}`) }}
          </v-card-text>
        </v-col>

        <v-col class="text-right pa-0" align-self="center" cols="12" sm="8">
          <v-text-field
            v-if="companyContactTags[tag].hasPhoneMask"
            color="accentSuite"
            v-model="tagsEdit[tag]"
            class="pa-0 my-0 body-2"
            autocomplete="off"
            :placeholder="companyContactTags[tag].placeholder"
            :rules="[
              (v) =>
                v
                  ? v.length <= 20 || $t('textFieldRules.max20characters')
                  : true,
            ]"
            :hint="$t('companyProfile.phoneHint')"
          />
          <v-text-field
            v-else
            color="grey"
            v-model="tagsEdit[tag]"
            :disabled="companyContactTags[tag].disabled"
            :type="companyContactTags[tag].type"
            :placeholder="companyContactTags[tag].placeholder"
            class="pa-0 my-0 body-2"
            :rules="tagRules"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { companyTags, companyContactTags } from "@//helpers/variables/tags";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { mask } from "vue-the-mask";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CompanyContactInfo",
  directives: { mask },
  data() {
    return {
      errorMessages,
      loading: false,
      tagsEdit: {},
      companyContactTags,
      companyTags,
      impactedTemplates: [],
    };
  },

  watch: {
    tagsEdit: {
      deep: true,
      handler(newVal) {
        this.$emit("updateTagsInfo", newVal);
      },
    },
  },

  beforeMount() {
    this.getCompanyInfo();
  },

  computed: {
    ...mapGetters(["company"]),

    editableCompanyTagsNames() {
      return Object.keys(this.companyContactTags);
    },

    tagRules() {
      return [
        (v) =>
          String(v).length <= 1490 ||
          this.$t("textFieldRules.max1490characters"),
      ];
    },

    editingTemplate() {
      return this.$route.name === "TemplateEdit";
    },
  },

  methods: {
    ...mapMutations(["setSnackBar"]),

    resetFields() {
      this.updateTags();
    },

    async getCompanyInfo() {
      this.loading = true;
      const snackbarConfig = { show: true };
      if (this.company) {
        this.updateTags();
      } else {
        snackbarConfig.message = errorMessages.unknown;
        snackbarConfig.color = "error";
        this.setSnackBar(snackbarConfig);
      }

      this.loading = false;
    },

    updateTags() {
      this.tagsEdit = companyTags.reduce(
        (a, e) => (this.company[e] ? (a[e] = this.company[e]) : -1, a),
        {}
      );
    },
  },
};
</script>
<style>
.v-tab {
  text-transform: none;
}

.fixed-element {
  position: sticky;
  top: 0;
  z-index: 4;
}

.uk-timeline .uk-timeline-item {
  display: flex;
  position: relative;
}

.uk-timeline .uk-timeline-item::before {
  background: #dadee4;
  content: "";
  height: 100%;
  left: 12px;
  position: absolute;
  top: 24px;
  width: 1px;
}

.uk-timeline .uk-timeline-item:last-of-type::before {
  background: #dadee4;
  content: "";
  height: 100%;
  left: 12px;
  top: 24px;
  width: 0px;
}

.uk-badge {
  background: var(--v-primary-base);
  border-radius: 50%;
  padding: 3px 8px;
  font-size: 0.9rem;
  color: #fff;
  font-weight: bold;
}

.uk-timeline .uk-timeline-item .uk-timeline-icon .uk-badge {
  margin-top: 20px;
  width: 40px;
  height: 40px;
  font-family: "Roboto";
}

.uk-timeline .uk-timeline-item .uk-timeline-content {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 0 0 1rem;
  font-family: "Roboto";
}
</style>
