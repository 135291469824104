<template>
  <div>
    <CompanyPlans />
    <v-divider />
    <CompanyTransactions class="mt-6" />
  </div>
</template>

<script>
import CompanyPlans from "@/components/billing/CompanyPlans.vue";
import CompanyTransactions from "@/components/billing/CompanyTransactions.vue";

export default {
  components: { CompanyPlans, CompanyTransactions },

  data: () => ({}),
};
</script>
